import React, { Children } from 'react'

// styles
import './Investment.css'
import check from '../../assets/investment/Vector-2.png'

export default function BodyPoints({ items }) {
    
    
    return (
        <>
        <div>
            <div key={items.structure1} className="body-points-circle">
                <img
                    src={check}
                    alt="check-mark"
                    className='check' />
            </div>
            <p>{items.structure1}</p>
        </div>

        <div>
            <div key={items.structure2} className="body-points-circle">
                <img
                    src={check}
                    alt="check-mark"
                    className='check' />
            </div>
            <p>{items.structure2}</p>
        </div>

        <div>
            <div key={items.structure3} className="body-points-circle">
                <img
                    src={check}
                    alt="check-mark"
                    className='check' />
            </div>
            <p>{items.structure3}</p>
        </div>

        <div>
            <div key={items.structure4} className="body-points-circle">
                <img
                    src={check}
                    alt="check-mark"
                    className='check' />
            </div>
            <p>{items.structure4}</p>
        </div>
        </>
    )
}
