import React from 'react'
import BodyPoints from './BodyPoints'
import IntroItem from './IntroItem'
import TransactionFocus from './TransactionFocus'
import IndustryCard from './IndustryCard'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import parse from 'html-react-parser';

// styles/assets
import './Investment.css'

const transactionFocus = [
  {number: 1, text: 'Special situation workout group assets'},
  {number: 2, text: 'Time-critical or complex opportunities'},
  {number: 3, text: 'Corporate divestitures and carve-outs'},
  {number: 4, text: 'Platforms with strong M&A add-on potential'},
  {number: 5, text: 'Private sales, including founder / family-owned businesses'},
  {number: 6, text: 'In need of recapitalization or restructuring '},
]


export default function Investment({documents}) {

  const industryFocus = documents.filter( doc => doc._fl_meta_.schema === "industryFocus").map( doc => doc)

  const industryFocusAlpha = industryFocus.sort((a, b) => a.industryFocusTitle.localeCompare(b.industryFocusTitle))

  const [ref, inView] = useInView({
    rootMargin: '10px',
    triggerOnce: true
  });

  const [ref2, inView2,] = useInView({
    /* Optional options */
    rootMargin: '10px',
    triggerOnce: false
});

  const variants = {
    visible: { opacity: 1, scale: 1, y: 1 },
    hidden: {
      opacity: 0,
      scale: 0.65,
      y: 20
    },
  };

  return (
    <div>
      {/* hero img starts */}
      {documents.filter(doc => doc._fl_meta_.schema === "investmentCriteria").map(doc=> 
      <motion.div 
      initial={{ x: 0, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1.5, ease: "easeOut" }}
      className="investment-hero-img">
        <div
          className='hero-title-header'>
          <h1 className='investment-hero-title'>
            {doc.heroTitle}
          </h1>
          <div className='shade' />
        </div>
      </motion.div>
      )}
      {/* hero img ends */}


      {/* investment intro starts */}
      {documents.filter(doc => doc._fl_meta_.schema === "investmentCriteria").map(doc=>
      <motion.div 
        initial={{ x: 0, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.5, ease: "easeOut", delay: 1 }}
        className="intro-text-box">
        <p className='intro-text contianer'>
          {doc.pageIntro}
        </p>
      </motion.div>
      )}
      {/* investment intro ends */}

      {/* Intro Items Start */}
        {documents.filter(doc => doc._fl_meta_.schema === "investmentCriteria").map(doc=>
          <IntroItem
            items={doc}
          />
        )}
      {/* Intro Items Ends */}


      {/* investment body image starts */}
      <div className="investment-body-img">
        <div className='shade1' />
        {documents.filter(doc => doc._fl_meta_.schema === "investmentCriteria").map(doc =>
          <motion.div
            ref={ref}
            animate={inView ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 1, ease: "easeOut" }}
            className="body-point contianer"
          >
            <BodyPoints
              items={doc}
            />
          </motion.div>
        )}
      </div>
      {/* investment body image ends */}

      
      {/* Transaction Focus starts */}
      <div className='transaction-focus contianer'>
        <h2>Transaction Focus</h2>
        <motion.div 
          ref={ref2}
          animate={inView2 ? "visible" : "hidden"}
          variants={variants}
          transition={{ duration: 1, ease: "easeOut" }}
          className='transaction-focus-points'>
          {documents.filter(doc => doc._fl_meta_.schema === "investmentCriteria").map(doc =>
            <TransactionFocus
              items={doc}
            />
          )}
        </motion.div>
      </div>
      {/* Transaction Focus ends */}


      {/* Industry Focus starts */}
      <div className='industry-focus'>
        <h2>Industry Focus</h2>
        {/* <h5>Hover over each card to learn more</h5> */}
        <div className='industry-grid'>
        {industryFocusAlpha.map(data => ( 
          <IndustryCard 
          data={data.icon}
          text={data.industryFocusTitle}
          key={data.id}
          // alt={data.alt}
          title={data.iconTitle}
          />
        ))}
        </div>
      </div>
      {/* Industry Focus ends */}
    </div>
  )
}
