import React from 'react';
import { Routes, Route} from 'react-router-dom';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { useCollection } from './hooks/useCollection';

// styles
import './App.css';

// pages
import Home from './Pages/Home/Home'
import Investment from './Pages/Investment/Investment'
import Contact from './Pages/Contact/Contact'
import About from './Pages/About/About'

function App() {
  const { documents } = useCollection('fl_content')

  return (
    <>
    {documents && 
    <div className="App">
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home documents={documents}/>} />
        <Route path='/Investment' element={<Investment documents={documents} />} />
        <Route path='/About' element={<About documents={documents}/>} />
        <Route path='/Contact' element={<Contact documents={documents}/>} />
      </Routes>
      <Footer documents={documents}/>
    </div>
    }
    </>
  );
}

export default App;
