import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/Paradigm_LO_header.jpg'


// styles
import './Navbar.css'


export default function Navbar() {
  return (
    <header>
      <div className='header-logo'>
      <a href="/" style={{textDecoration: 'none'}}>
        <img src={logo} alt="Paradigm Header Logo" />
        {/* <h1 className='logo'>PARADIGM</h1> */}
      </a>
      </div>
      <input type="checkbox" id="nav-toggle" className='nav-toggle' />
      <nav>
        <ul>
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/About'>About</Link></li>
          <li><Link to='/Investment'>Investment Criteria</Link></li>
          <li><Link to='/Contact'>Contact Us</Link></li>
        </ul>
      </nav>
      {/* the for="" variable lets this <label> work with the element id that you specify (in this case, we are pointing to the nav-toggle check box) */}
      <label for="nav-toggle" className='nav-toggle-label'>
        <span></span>
      </label>
    </header>
  )
}

