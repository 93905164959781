import React, { useEffect, useState } from "react";

// styles
import paradigm from '../../assets/contact/paradigm.png'
import "../Contact/Contact.css";

const DisplayContainers = ({documents}) => {
  
  return (
    <div >
      <div className="contactUsGrid">
        <div className="contact-top-bg"><div className="shade"/></div>
        <div className="contact-info"><ContactAndLocationInfo documents={documents} /></div>
        <div className="contact-box-padding"><ContactForm /></div>
        <div><GoogleMapsIframe/></div>
      </div>
    </div>
  )
}

const ContactAndLocationInfo = ({documents}) => {
  return (
    <>
    {documents.filter (doc => doc._fl_meta_.schema === "contactUs").map(doc =>
    <div className="box-padding">
      <h1 className="contact-title">
        {/* PARADIGM EQUITY PARTNERS */}
        <img src={paradigm} alt="" />
      </h1>
      <h5>Address</h5>
      <div className="grid-contact-text">
        {doc.address}
      </div>
      <h5 className="t-s">Phone</h5>
      <div className="grid-contact-text">
        {doc.phone}
      </div>
      <h5 className="t-s">Email</h5>
      <div className="grid-contact-text">
        {doc.email}
      </div>
    </div>
    )}
    </>
  );
};


const ContactForm = (params) => {

  const [enteredFirstName, setFirstName] = useState('');
  const [enteredFirstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");

  const [enteredLastName, setLastName] = useState('');
  const [enteredLastNameError, setLastNameError] = useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");

  const [enteredPhoneNumber, setPhoneNumber] = useState('');
  const [enteredPhoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");

  const [enteredCompanyName, setCompanyName] = useState('');
  const [enteredCompanyNameError, setCompanyNameError] = useState(false);
  const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState("");

  const [enteredEmailAddress, setEmailAddress] = useState('');
  const [enteredEmailAddressError, setEmailAddressError] = useState(false);
  const [emailAddressErrorMessage, setEmailAddressErrorMessage] = useState("");

  const [enteredTextArea, setTextArea] = useState('');
  const [enteredTextAreaError, setTextAreaError] = useState(false);
  const [textAreaErrorMessage, setTextAreaErrorMessage] = useState("");

  const [emptyFeilds, setEmptyFeilds] = useState(true);



  return (
    <form className="formPosition" id="contact-form" action="https://formsubmit.co/info@paradigmeps.com" method="POST">
      <div className="form-row" >
        <select required id="inputReasoning" name="Reason" className="form-control">
          <option selected disabled value="">Reason for Message...</option>
          <option value="Business Opportunity" >Business Opportunity</option>
          <option value="Careers" >Careers</option>
          <option value="Advisory" >Advisory</option>
          <option value="Advisory" >Other</option>
        </select>

        <div className="c-r-2 flexy space-even">          <div className="form-row">
          <input required type="text" name="First-Name" className={enteredFirstNameError ? "errorBox" : "form-control"} id="inputFirstName" placeholder="First Name" maxLength="45" 
          // onChange={firstNameChangeHandler}
          ></input>
          {enteredFirstNameError && <p className="errorTextColor">{firstNameErrorMessage}</p>}
        </div>

          <div className="form-row">
            <input required type="text" name="Last-Name" className={enteredLastNameError ? "errorBox" : "form-control"} id="inputLastName" placeholder="Last Name" maxLength="45" 
            // onChange={lastNameChangeHandler}
            ></input>
            {enteredLastNameError && <p className="errorTextColor">{lastNameErrorMessage}</p>}
          </div>
        </div>

        <div className="c-r-2 flexy space-even">
          <div className="form-row">
            <input required type="tel"  pattern="^[0-9]{3,45}$" name="Phone-Number" className={enteredPhoneNumberError ? "errorBox" : "form-control"} id="inputPhoneNumber" placeholder="Phone Number" maxLength="20" 
            // onChange={phoneNumberChangeHandler}
            ></input>
            {enteredPhoneNumberError && <p className="errorTextColor">{phoneNumberErrorMessage}</p>}
          </div>

          <div className="form-row">
            <input type="text" name="Company"  className={enteredCompanyNameError ? "errorBox" : "form-control"} id="inputCompany" placeholder="Company" maxLength="45" 
            // onChange={companyNameChangeHandler}
            ></input>
            {enteredCompanyNameError && <p className="errorTextColor">{companyNameErrorMessage}</p>}
          </div>
        </div>


        <div className="form-row">
          <input required type="email" name="Email" className={enteredEmailAddressError ? "errorBox" : "form-control"} id="inputEmail" placeholder="Email" maxLength="253" 
          // onChange={emailAddressChangeHandler}
          >

          </input>
          {enteredEmailAddressError && <p className="errorTextColor">{emailAddressErrorMessage}</p>}
        </div>

        <div className="form-row">
          <textarea required name="Message" className={enteredTextAreaError ? "errorBox" : "form-control"} id="formControlTextArea" rows="5" placeholder="Your Message..." 
          // onChange={textAreaChangeHandler}
          ></textarea>
          {enteredTextAreaError && <p className="errorTextColor">{textAreaErrorMessage}</p>}
        </div>

        <input type="text" name="_honey" className="honey"></input>
        <input type="hidden" name="_next" value="http://localhost:3000/" ></input>

        <button type="submit" 
        // onClick={inputValidHandler} 
        className="primary-btn">Send Email</button>
      </div>
    </form>

  )
}

const GoogleMapsIframe = () => {
  return (
    <div className="iframeContainer">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.3900174186124!2d-118.41377000000001!3d34.059515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bbf30ed24f49%3A0x28bc0e2befefdf0e!2s2029%20Century%20Park%20E%20Suite%20400%2C%20Los%20Angeles%2C%20CA%2090067!5e0!3m2!1sen!2sus!4v1683170257607!5m2!1sen!2sus"
        width="100%"
        height="650"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default function Contact({documents}) {
  return (
    <div>
      <DisplayContainers documents={documents}/>
    </div>
  );
}
