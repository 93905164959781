import React, {useEffect, useState} from 'react'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db, storage } from '../firebase/config'
// import { collection} from 'firebase/firestore'



export default function useStorage(file) {
    const [image, setImage] = useState(null)
    const storageRef = ref(storage, file);
    // const collectionRef = collection(db, 'images')
    // const { addDocument } = useFirestore('images')
    
    useEffect(() => {
        // const storage = getStorage();
       getDownloadURL(storageRef)
            .then((url) => 
            setImage(url)
        )
        
            .catch((error) => {
                error && console.log(error)
            });

    }, [file])

    return { image }
}
