import React from 'react'

// styles
import './Investment.css'
import check from '../../assets/investment/right.png'

export default function TransactionFocus({items}) {
  return (
      <>
      <div key={items.transactionFocus1}className='focus-points'>
          <div className='focus-points-circle'>
              <img className='transaction-check' src={check} alt="https://www.flaticon.com/free-icons/next" title="next icons" />
          </div>
          <p>{items.transactionFocus1}</p>
      </div>

      <div key={items.transactionFocus2}className='focus-points'>
          <div className='focus-points-circle'>
              <img className='transaction-check' src={check} alt="https://www.flaticon.com/free-icons/next" title="next icons" />
          </div>
          <p>{items.transactionFocus2}</p>
      </div>

      <div key={items.transactionFocus3}className='focus-points'>
          <div className='focus-points-circle'>
              <img className='transaction-check' src={check} alt="https://www.flaticon.com/free-icons/next" title="next icons" />
          </div>
          <p>{items.transactionFocus3}</p>
      </div>

      <div key={items.transactionFocus4}className='focus-points'>
          <div className='focus-points-circle'>
              <img className='transaction-check' src={check} alt="https://www.flaticon.com/free-icons/next" title="next icons" />
          </div>
          <p>{items.transactionFocus4}</p>
      </div>

      <div key={items.transactionFocus5}className='focus-points'>
          <div className='focus-points-circle'>
              <img className='transaction-check' src={check} alt="https://www.flaticon.com/free-icons/next" title="next icons" />
          </div>
          <p>{items.transactionFocus5}</p>
      </div>

      <div key={items.transactionFocus6}className='focus-points'>
          <div className='focus-points-circle'>
              <img className='transaction-check' src={check} alt="https://www.flaticon.com/free-icons/next" title="next icons" />
          </div>
          <p>{items.transactionFocus6}</p>
      </div>

      </>
  )
}
