// Firebase
import { initializeApp} from 'firebase/app';

// Firestore services
import { getFirestore } from 'firebase/firestore';

// Storage
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAf2yVvlHvZXYvoHg1Djjcjq4uq15OjFks",
    authDomain: "paradigm-8d11f.firebaseapp.com",
    projectId: "paradigm-8d11f",
    storageBucket: "paradigm-8d11f.appspot.com",
    messagingSenderId: "506103764009",
    appId: "1:506103764009:web:ccd74bf1db4f7f6babb838"
  };

// initialize firebase
const firebaseApp = initializeApp(firebaseConfig)

// initialize services
const db = getFirestore();

// initialize storage
const storage = getStorage(firebaseApp);

export { db, storage }