import React from 'react'
import parse from 'html-react-parser';
import { motion } from 'framer-motion';

// styles
import './Investment.css'


export default function IntroItem({ items }) {
    console.log( items )
    return (
        <motion.div 
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1.5, ease: "easeOut", delay: 1 }}
            className='intro-icons contianer'>
            {/* item 1 */}
            <div key={items.sizeAndGeo1Icon} className="intro-item">
                {parse(items.sizeAndGeo1Icon)}
                <p>{parse(items.sizeAndGeo1Text)}</p>
            </div>

            {/* item 2 */}
            <div key={items.sizeAndGeo2Icon} className="intro-item">
                {parse(items.sizeAndGeo2Icon)}
                <p>{parse(items.sizeAndGeo2Text)}</p>
            </div>

            {/* item 3 */}
            <div key={items.sizeAndGeo3Icon} className="intro-item">
                {parse(items.sizeAndGeo3Icon)}
                <p>{parse(items.sizeAndGeo3Text)}</p>
            </div>
            
        </motion.div>
    )
}
