import React from 'react'
import Linkedin from '../assets/linkedin-icon.png';
import parse from 'html-react-parser';
import useStorage from '../hooks/useStorage';

// assets
// import pdf from '../assets/Paradigm-Equity-Partners-One-Pager.pdf'
import logo from '../assets/white-logo_11x.png'

// styles
import './Footer.css'


export default function Footer({ documents }) {
  const file = documents.filter(doc => doc._fl_meta_.schema === "footer").map(doc => doc.pdfFactSheetTitle)
  const { image } = useStorage(`flamelink/media/${file}`)

  return (
    <div id="footer" >
      <div className='flexy contianer'>
        <div className='left-foot box-padding'>
          <h2 className='logo'>PARADIGM</h2>
          
          {/* email starts */}
          {documents.filter(doc => doc._fl_meta_.schema === "footer").map(doc =>
          <div className='left-img flexy'>
            {parse(doc.emailIcon)}
            <p>
              <a href={doc.emailLink}>{doc.email}</a>
            </p>
          </div>
          )}
          {/* email ends */}

          {/* phone number starts */}
          {documents.filter(doc => doc._fl_meta_.schema === "footer").map(doc =>
            <div className='left-img flexy'>
              {parse(doc.phoneIcon)}
              <p>
                <a href={doc.phoneLink}>{doc.phoneNumber}</a>
                </p>
            </div>
          )}
          {/* phone number ends */}
         
          {/* location starts */}
          {documents.filter(doc => doc._fl_meta_.schema === "footer").map(doc =>
            <div className='left-img flexy'>
              {/* <img src={locationIMG} /> */}
              {parse(doc.locationIcon)}
            <p>{doc.location}</p>
            </div>
          )}
          {/* location ebds */}
          
          {/* LinkedIn starts */}
          {documents.filter(doc => doc._fl_meta_.schema === "footer").map(doc =>
          <div className='footer-icons'>
            <a 
            class="footer-icon" 
            href={doc.linkedInUrl}
            target="_blank"
            >
              {/* <img src={Linkedin} alt="Linkedin logo" /> */}
              {parse(doc.linkedInLogo)}
            </a>
          </div>
          )}
          {/* LinkedIn ends */}

        </div>
        <div className='right-foot box-padding' >
          <img src={logo} className='paradigm-logo' alt="Paradigm Equity Partners Logo" />
          <a href={image} target="_blank" class="pdf-btn">Paradigm Fact Sheet PDF</a>
          {/* <p> Download our fact sheet and learn more on how we can help you!</p> */}
        </div>
        
      </div>
      <div className='sub-footer flexy space-between'>
        <div class="copy">© 2022 Paradigm Capital Partners</div>
        <div class="copy">Created By <a href="https://script-rocket.com" target="_blank"> Script Rocket</a></div>
      </div>
    </div>
  )
}