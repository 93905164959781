import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import parse from 'html-react-parser';

import './About.css';

import mainIMG from '../../assets/about/about-img-1.png';
import mainIMG2 from '../../assets/about/about-img-2-1.jpg'

export default function Investment({documents}) {

    const [ref, inView] = useInView({
        rootMargin: '10px',
        triggerOnce: true
    });

    const [ref2, inView2] = useInView({
        rootMargin: '10px',
        triggerOnce: true
      });
    

    const variants = {
        visible: { opacity: 1, scale: 1, y: 1 },
        hidden: {
            opacity: 0,
            scale: 0.65,
            y: 20
        },
    };

    const industryFocus = documents.filter( doc => doc._fl_meta_.schema === "industryFocus").map( doc => doc)

    const industryFocusAlpha = industryFocus.sort((a, b) => a.industryFocusTitle.localeCompare(b.industryFocusTitle))


    return (
        <div className='about-pg'>
            <motion.div 
                initial={{ x: 0, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.5, ease: "easeOut"}}
                className="about-hero-img">
                {documents && documents.filter( doc => doc._fl_meta_.schema === "homePage_copy_1").map(doc => 
                <h1 className='about-hero-title box-padding'>{doc.heroTitle}</h1>
                )}
                <div className='about-hero-shade'/>
            </motion.div>
            <motion.div 
                initial={{ x: 0, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.5, ease: "easeOut", delay: 1 }} 
                className='contianer about-con box-padding'>
            {documents && documents.filter( doc => doc._fl_meta_.schema === "homePage_copy_1").map(doc => 
            <p>
                {doc.aboutPageIntro}
            </p>
            )}
            </motion.div>
            <div className='contianer about-con box-padding'>
                {documents && documents.filter( doc => doc._fl_meta_.schema === "homePage_copy_1").map(doc => 
                <div className='flexy about-con'>
                    <div
                        className='about-text-pg left'>
                        <h2 className='about-header'>{doc.focusTitle}</h2>
                        {parse(doc.focusIntro)}
                        <motion.div 
                        ref={ref}
                        animate={inView ? "visible" : "hidden"}
                        variants={variants}
                        transition={{ duration: 1, ease: "easeOut" }} 
                        className='about-list flexy space-between'>
                        <ul>
                            {industryFocusAlpha.map(doc => 
                                <li>{doc.industryFocusTitle}</li>
                        )}
                        </ul>
                        </motion.div>
                    </div>
                    <div className='about-img-right right'>
                        {parse(doc.focusImage)}
                    </div>
                </div>
                )}
            </div>
            <div className='about-float-btn box-padding'>
                <a href='/Investment' className='primary-btn'>Explore Our Investment Criteria</a>
            </div>
            {documents && documents.filter( doc => doc._fl_meta_.schema === "homePage_copy_1").map(doc =>
            <div className='about-body-bg box-padding flexy flexy-center'>
                <motion.div 
                        ref={ref2}
                        animate={inView2 ? "visible" : "hidden"}
                        variants={variants}
                        transition={{ duration: 1, ease: "easeOut" }}  
                        className='contianer'>
                    <h2>{doc.capabilitiesTitle}</h2>
                    {parse(doc.capabilitiesText)}
                </motion.div>
            </div>
            )}

            {documents && documents.filter( doc => doc._fl_meta_.schema === "homePage_copy_1").map(doc =>
            <div className='contianer about-con flexy box-padding about-bottom'>
                {/* <div className='left'><img src={mainIMG2} /></div> */}
                {parse(doc.beliefImage)}
                <div className='right box-padding beliefs-text'>
                    <h2 className='about-header'>{doc.beliefTitle}</h2>
                    {parse(doc.beliefText)}
                    {/* <p>We believe that business is done by building long-lasting relationships. At Paradigm, we view organizations as partners that we treat with mutual respect and transparency.</p>
                    <p>Our commitments to partners, sustainability, and communities drive Paradigm’s investment decisions, as we aim to leave a lasting and positive impact on the organizations and communities we work with.</p> */}
                </div>
            </div>
            )}

        </div>
    )
};
