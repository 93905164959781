import React, { useRef, useEffect } from 'react';
import './Home.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import parse from 'html-react-parser';

// assets
import { IoArrowDownOutline } from "react-icons/io5";
import heroVIDEO from '../../assets/home/paradigm-hero.mp4';


export default function Home({documents}) {

  const [ref, inView] = useInView({
    rootMargin: '10px',
    triggerOnce: true
  });

  const [ref2, inView2] = useInView({
    rootMargin: '10px',
    triggerOnce: true
  });

  const [ref3, inView3] = useInView({
    rootMargin: '10px',
    triggerOnce: true
  });

  const variants = {
    visible: { opacity: 1, scale: 1, y: 1 },
    hidden: {
      opacity: 0,
      scale: 0.65,
      y: 20
    },
  };

  useEffect(() => {
    document.querySelector('.hm-video').classList.add('video-show')
  }, [])
  


  return (
    <div class="hm-body">
      {/* Hero start */}
      <motion.div 
        id='hm-hero' 
        class="flexy"
        >
        <video 
        className='hm-video'
        loop="true" 
        autoplay="autoplay" 
        muted 
        playsInline
        // poster={heroIMG} 
        id="bgvid">
          <source src={heroVIDEO} type="video/mp4"></source>
        </video>
        <div className='shade'/>
        {documents.filter (doc => doc._fl_meta_.schema === "homePage").map(doc =>  
        <motion.h1
                initial={{ x: 0, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.5, ease: "easeOut", delay: 1 }}
                className="magic"  
                class="hm-hero-title" >
                  {doc.heroVideoTitle}
        </motion.h1>
        )}
        {/* arrow */}
        <a href="#hm-about" class="hero__scroll-btn">
          Find out more 
          <IoArrowDownOutline class="bi bi-arrow-down-short"/>
          {/* <img src={ArrowDownwardIcon} class="bi bi-arrow-down-short"></img> */}
        </a>
      </motion.div>
      {/* Hero end */}

      {/* About start */}
      {documents.filter (doc => doc._fl_meta_.schema === "homePage").map(doc => 
        <div id="hm-about" className='contianer'>
          <div class="flexy box-padding">
            <motion.div
              initial={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1.5, ease: "easeOut", delay: 1 }}
              class="about-text">
                <h2>{doc.aboutUsTitle}</h2>
                <p>
                {parse(doc.aboutUsIntro)}
                </p>
                <a class="primary-btn" href='/About'>Learn More</a>
            </motion.div>
            <motion.div 
              class="about-img"
              initial={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1.5, ease: "easeOut", delay: 1 }}
              >
              {/* <img src={aboutIMG} /> */}
              {parse(doc.aboutUsImage)}
            </motion.div>
          </div>
        </div>
        )}
      {/* About end */}

      {/* Service start */}
      {documents.filter (doc => doc._fl_meta_.schema === "homePage").map(doc =>
        <div id="hm-service" >
          <div class="flexy contianer">
            <div class="service-img">
            <div className='shade'/>
              <h2>{doc.secondImageText}
              </h2>
            </div>
            <div class="service-content flexy">
              {/* service 1 */}
              <div
                class="serivce-1 flexy">
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                variants={variants}
                transition={{ duration: 1, ease: "easeOut" }}
                className="magic" 
                class="service-img-container">
                <div class="serivce-title">{doc.core1Title}</div>
                {/* <img src={serviceIMG3} /> */}
                {parse(doc.core1Icon)}
              </motion.div>
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                variants={variants}
                transition={{ duration: 1, ease: "easeOut" }}
                className="magic" class="serivce-body">
                  {parse(doc.core1Text)}
              </motion.div>
            </div>

            {/* service 2 */}
            <div class="serivce-1 flexy">
              <motion.div
                ref={ref2}
                animate={inView2 ? "visible" : "hidden"}
                variants={variants}
                transition={{ duration: 1, ease: "easeOut", delayChildren: 1 }}
                className="magic" class="service-img-container">
                <div class="serivce-title">{doc.core2Title}</div>
                {/* <img src={serviceIMG2} /> */}
                {parse(doc.core2Icon)}
              </motion.div>
              <motion.div
                ref={ref2}
                animate={inView2 ? "visible" : "hidden"}
                variants={variants}
                transition={{ duration: 1, ease: "easeOut"}}
                class="serivce-body">
               {parse(doc.core2Text)}
              </motion.div>
            </div>

              {/* service 3 */}
              <div class="serivce-1 flexy">
                <motion.div
                ref={ref3}
                animate={inView3 ? "visible" : "hidden"}
                variants={variants}
                transition={{ duration: 1, ease: "easeOut" }}
                 class="service-img-container">
                  <div 
                  class="serivce-title">{doc.core3Title}</div>
                  {/* <img src={serviceIMG1} /> */}
                  {parse(doc.core3Icon)}
                </motion.div>
                <motion.div
                ref={ref3}
                animate={inView3 ? "visible" : "hidden"}
                variants={variants}
                transition={{ duration: 1, ease: "easeOut" }} class="serivce-body">
                  {parse(doc.core3Text)}
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Service end */}
     
      {/* Footer Banner start */}
      {documents.filter (doc => doc._fl_meta_.schema === "homePage").map(doc =>
      <div id="hm-footer" class="flexy">
      <div className='shade'/>
        <h2 class="footer-banner-text">
            {doc.thirdImageText}
          </h2>
      </div>
      )}
      {/* Footer Banner end */}

      {/* Investment start */}
      {documents.filter (doc => doc._fl_meta_.schema === "homePage").map(doc =>
      <div id="hm-invest" class="flexy contianer">
          <div class="img-text">
            <h2>{doc.investmentCriteriaTitle}</h2>
          </div>
          <div id="invest-text" class="box-padding">
            {parse(doc.investmentCriteriaText)}
            <a class="primary-btn" href='/Investment'>Learn More</a>
          </div>
        </div>
      )}
      {/* Investment end */}
 
      {/* Home body start */}
      {documents.filter(doc => doc._fl_meta_.schema === "homePage").map(doc =>
        <div id="hm-body" class="flexy contianer box-padding container">
          {/* <Fader> */}
          <div class="small-para">
            {parse(doc.ethics1)}
          </div>
          <div class="small-para">
            {parse(doc.ethics2)}
          </div>
        </div>
      )}
      {/* Home body end */}
     
    </div>
  )
}
