import React from 'react'
import parse from 'html-react-parser';


// styles/assets
import './IndustryFocus.css'


export default function IndustryCard({data, text, key, title}) {


    return (
        <div className='industry-icon-container' title={title} key={key}>
            {/* <img 
                alt={alt}
                
                src={data}
            /> */}
            {parse(data)}
            <div className='industry-icon-title'>{text}</div>
        </div>

        // <div
        //     key={key}
        //     style={{ background: `url(${(data)})` }}
        //     class="plain-card-3">
        //     <div className='blue-hue'>
        //         <div
        //             class="card-text">
        //             {text}
        //         </div>
        //     </div>
        // </div>
    )
}
